@import 'node_modules/font-awesome/scss/font-awesome';

* { box-sizing: border-box; }

/*
 * Make anchors behave like they did in Bootstrap 4, with no underline
 * unless hovered over.  dropdown-items never show underlines.
 */
a {
  text-decoration: none !important;
}
a:not(.dropdown-item, .navbar-brand):hover {
  text-decoration: underline !important;
}

/*
 * Perhaps due to the nature in how I generate the HTML for album links,
 * I can't put this style in the scss for the component itself as it ends
 * up not being processed.
 */
.albumLinkInParagraph {
  font-style: italic;
}

.youTubeLinkInParagraph {
  img {
    width: 24px;
    margin-right: 5px;
    margin-left: 5px;
    position: relative;
    bottom: 2px; // looks too low without this
  }
}